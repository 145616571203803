import Axios from "axios";
import {
  subWisePVpep,
  subWisePVpepwiseCandidates,
  subWisePVpepwiseBlankMarkSheet,
  subWisePVpepwisePVstudentList,
  subWisePVpepwiseMarkSheet,
  savePVmarks,
  downloadPVAttandance,
} from "../../../api";
import {
  SUBJECT_WISE_PV_PAPER,
  SUBJECT_WISE_PV_CANDIDATE_LIST,
  UNSET_SUBJECT_WISE_PV_CANDIDATE_LIST,
} from "../../../mutation-types";


const state = {
  pvPepData: [],
  pvCandidateData: [],
};

const getters = {
  getPvPepData() {
    return state.pvPepData;
  },
  getPvCandidateData() {
    return state.pvCandidateData;
  },
};

const mutations = {
  [SUBJECT_WISE_PV_PAPER](state, result) {
    state.pvPepData = result;
  },
  [SUBJECT_WISE_PV_CANDIDATE_LIST](state, result) {
    state.pvCandidateData = result;
  },
  [UNSET_SUBJECT_WISE_PV_CANDIDATE_LIST](state, result) {
    state.pvCandidateData = {};
  },
};

const actions = {
  fetchPvPepData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      Axios.get(subWisePVpep, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING");
          var result = response.data.data;
          console.log("Tanvir, Sub wise PV pep data->", result);
          commit("SUBJECT_WISE_PV_PAPER", result);
          resolve(response.data.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING");
          console.log(error);
          reject();
        });
    });
  },


  fetchPvCandidateData({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      Axios.get(subWisePVpepwiseCandidates, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING");
          var result = response.data;
          console.log("Tanvir, Sub wise candidates data->", result);
          commit("SUBJECT_WISE_PV_CANDIDATE_LIST", result);
          resolve(response.data);
        })
        .catch(function (error) {
          commit("UNSET_LOADING");
          console.log(error);
          reject();
        });
    });
  },

  clearPvCandidateData({ commit }) {
    commit("UNSET_SUBJECT_WISE_PV_CANDIDATE_LIST");
    console.log("Tanvir, Sub wise candidates data cleared");  
  },


  PracticalBlankMarkSheet({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading  Opening
      Axios.get(subWisePVpepwiseBlankMarkSheet, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing

          let url = response.data.data;
          console.log(url);

          // showing on a new tab
          var fileLink = document.createElement("a");

          fileLink.href = url;
          fileLink.download = "PVCandidateList_" + Date.now() + ".pdf";
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();

          resolve();
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
  PracticalVivaStudentMarks({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading  Opening
      Axios.get(subWisePVpepwiseMarkSheet, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing

          let url = response.data.data;
          console.log(url);

          // showing on a new tab
          var fileLink = document.createElement("a");

          fileLink.href = url;
          fileLink.download = "PVCandidateList_" + Date.now() + ".pdf";
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();

          resolve();
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
  PracticalVivaStudentList({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading  Opening
      Axios.get(subWisePVpepwisePVstudentList, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing

          let url = response.data.data;
          console.log(url);

          // showing on a new tab
          var fileLink = document.createElement("a");

          fileLink.href = url;
          fileLink.download = "PVCandidateList_" + Date.now() + ".pdf";
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();

          resolve();
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
  submitPracticalVivaMarks({ commit }, pvMarks) {
    console.log("Tanvir! submit pv mark", pvMarks);
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', { color: 'primary' }); // loading Opening
      Axios.post(savePVmarks, pvMarks)
        .then(function (response) {
          commit('UNSET_LOADING');
          if (response.data.message === "Mark uploaded successfully! But skipped 1 scripts") {
            console.log("error")
            commit("SET_SNACKBAR", {
              msg: "invalid marks unchaged",
              color: "red"
            });
          }
          resolve(response.data.message);
        })
        .catch(function (error) {
          commit('UNSET_LOADING'); // loading Closing
          console.log(error);
          console.log('in submit error');
          commit('SET_SNACKBAR', {
            msg: failedMsg,
            color: 'red',
          });
          reject();
        });
    });
  },
  PracticalVivaStudentAttandance({ commit }, searchParam) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING", {
        color: "primary",
      });
      // loading  Opening
      Axios.get(downloadPVAttandance, {
        params: searchParam,
      })
        .then(function (response) {
          commit("UNSET_LOADING"); // loading Closing

          let url = response.data.data;
          console.log(url);

          // showing on a new tab
          var fileLink = document.createElement("a");

          fileLink.href = url;
          fileLink.download = "PVCandidateList_" + Date.now() + ".pdf";
          fileLink.target = "_blank";
          document.body.appendChild(fileLink);
          fileLink.click();

          resolve();
        })
        .catch(function (error) {
          commit("UNSET_LOADING"); // loading Closing
          console.log(error);
          reject();
        });
    });
  },
};



export default {
  state,
  getters,
  actions,
  mutations
};