import Axios from 'axios';
import {
    REPORTED_STUDENT_LIST
} from '../../mutation-types';
import {
    reportedStudentList,
    updateReportedYear,
    instResultLotData
} from '../../api';

const state = {
    reportedStudent: [],
};
const getters = {
    getReportedStudentData() {
        return state.reportedStudent;
    }
};
const mutations = {
    [REPORTED_STUDENT_LIST](state, result) {
        state.reportedStudent = result;
    },
};
const actions = {
    fetchReportedStudent({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary",
            });
            Axios.get(reportedStudentList, {
                params: searchParam,
            })
                .then(function (response) {
                    commit("UNSET_LOADING");
                    var result = response.data.data;
                    console.log("Tanvir, this is reported list->", result);
                    commit("REPORTED_STUDENT_LIST", result);
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    commit("UNSET_LOADING");
                    console.log(error);
                    reject();
                });
        });
    },

    updateReportedStudent({ commit }, schema) {
        let data = schema;
        return new Promise((resolve, reject) => {
            Axios.put(`${updateReportedYear}/${schema.id}`, data)
                .then(function (response) {
                    console.log("Tanvir! The response comes", response);
                    commit("SET_LOADING", { color: "primary" });
                    if (response.status) {
                        commit("SET_SNACKBAR", {
                            msg: response.data.status,
                            color: "green"
                        });
                        commit("UNSET_LOADING"); // Stop loading
                        resolve();
                    } else {
                        commit("UNSET_LOADING"); // Stop loading
                        reject(response.data.errors || {});
                    }
                })
                .catch(function (error) {
                    console.log("The error comes", error);
                    commit("UNSET_LOADING"); // Ensure loading is closed
                    if (error.response && error.response.status === 422) {
                        reject(error.response.data.errors); // Validation errors
                    } else {
                        reject({ general: "An unexpected error occurred." });
                    }
                });
        });
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};