import Axios from 'axios';
import {
    RESULT_LOT_LIST
} from '../../mutation-types';
import {
    resultLotDataList,
    instResultLotData
} from '../../api';

const state = {
    resLotList: [],
};
const getters = {
    getResultLotList() {
        return state.resLotList;
    }
};
const mutations = {
    [RESULT_LOT_LIST](state, result) {
        state.resLotList = result;
    },
};
const actions = {
    fetchresLotList({ commit }, searchParam) {
        return new Promise((resolve, reject) => {
            commit("SET_LOADING", {
                color: "primary",
            });
            Axios.get(resultLotDataList, {
                params: searchParam,
            })
                .then(function (response) {
                    commit("UNSET_LOADING");
                    var result = response.data.data;
                    console.log("Tanvir, this is slot list->", result);
                    commit("RESULT_LOT_LIST", result);
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    commit("UNSET_LOADING");
                    console.log(error);
                    reject();
                });
        });
    }, 

    storeResLotData({ commit }, schema) {
        let data = schema;
        return new Promise((resolve, reject) => {
            Axios.post(instResultLotData, data)
                .then(function (response) {
                    console.log("the response comes", response);
                    commit("SET_LOADING", { color: "primary" });
                    if (response.data.status == "success") {
                        commit("SET_SNACKBAR", {
                            msg: response.data.message,
                            color: "green"
                        });
                        commit("UNSET_LOADING"); // loading Closing
                        resolve();
                    } else {
                        commit("UNSET_LOADING"); // loading Closing
                        reject(response.data.errors || {});
                    }
                })
                .catch(function (error) {
                    console.log("the error comes", error);
                    commit("UNSET_LOADING"); // Ensure loading is closed
                    if (error.response && error.response.status === 422) {
                        // Pass validation errors to the component
                        reject(error.response.data.errors);
                    } else {
                        // Handle other errors (optional)
                        reject({ general: "An unexpected error occurred." });
                    }
                });
        });
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};